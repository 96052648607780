body, html, #root {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  padding: 1rem;
  background-color: white;
  margin-bottom: 56px; /* Height of the footer */
}

footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: white;
  z-index: 1000;
}

@media (max-width: 768px) {
  main {
    padding: 1rem;
  }
}
